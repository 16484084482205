.wrapper {
  .image_wrapper {
    display: flex;
    gap: 20px;
  }
  .image_container {
    img {
      object-fit: contain;
      background-color: #edf1f4;
    }
  }
  :global .tooltip-wrapper {
    margin-bottom: 20px;
  }
}
